import React from 'react';
import { useSelector } from 'react-redux';
import CheckoutSuccess from '../components/views/CheckoutSuccess';

const CheckoutSuccessContainer = () => {
  const { bookingItem } = useSelector(state => state.booking);

  return (
    <>
      <CheckoutSuccess bookingItem={bookingItem} />
    </>
  );
};

export default CheckoutSuccessContainer;