import React from 'react';
import Modal from '../commons/Modal';

const SafariSetup = ({
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal
        title=' '
        isOpen={isOpen}
        isClosable={true}
        onClose={onClose}
        size='large exist-title'
        Content={(
          <div className='pr-20 pb-40 pl-20'>
            <p className='safari-setup-title'>
              Safari를 이용하여<br/>
              모바일 체크인을 진행하실 고객께서는 팝업차단을 해제하신 후 이용하여 주세요.
            </p>
            <p className='safari-setup-description-title'>
              팝업 허용 설정 방법
            </p>
            <span className='safari-setup-description-content'>
              1. 아이폰의 ‘설정’에서 ‘Safari’ 로 이동합니다.<br/>
              2. ‘Safari 설정’ 에서 ‘팝업 차단’ 옵션을 ‘끄기’로  변경하면 팝업 차단이 해제됩니다.<br/>
              3. 팝업차단을 해제한 후, 모바일 체크인 링크를 새로고침하세요.
            </span>
            <div className='info-notice-container'>
              <img className='info-notice mt-4 mr-4' src='../../assets/images/info-notice.png' alt='' />
              <span className='info-notice-text'>
                구글 크롬 혹은 카카오 브라우저를 이용하시면 더욱 편리하게 체크인이 가능합니다.
              </span>
            </div>
            <img className='safari-setup mt-15' src='../../assets/images/safari-setup.png' alt='' />
          </div>
        )}
      />
    </>
  );
};

export default SafariSetup;