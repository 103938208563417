import moment from 'moment';

// 'YYYYMMDD'를 'YYYY.MM.DD (요일)'로 파싱
export const parseEnDateByDayOfWeek = (date) => {
  if (!date) return date;
  var year = date.slice(0, 4);
  var month = date.slice(4, 6);
  var day = date.slice(6, 8);
  return `${year}.${month}.${day} (${moment(date).format('ddd')})`;
}

// 'YYYYMMDD'를 'YYYY년 MM월 DD일 (요일)'로 파싱
export const parseKoDateByDayOfWeek = (date) => {
  if (!date) return date;
  var year = date.slice(0, 4);
  var month = date.slice(4, 6);
  var day = date.slice(6, 8);
  return `${year}년 ${month}월 ${day}일 (${dayFormatter(moment(date).format('ddd'))})`;
}

const dayFormatter = (day) => {
  return {
    Sun: '일',
    Mon: '월',
    Tue: '화',
    Wed: '수',
    Thu: '목',
    Fri: '금',
    Sat: '토',
  }[day] || day;
};

// 유닉스 타임스탬프 값을 'YYYY.MM.DD HH:mm:ss'로 파싱
export const parseUnixTimestampToSeconds = (timestamp) => {
  if (!timestamp) return timestamp;
  return moment.unix(timestamp).format('YYYY.MM.DD HH:mm:ss');
}

// 'HHmm'를 'HH:mm'로 파싱
export const parseTimeToMinutes = (time) => {
  if (!time) return time;
  return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
}