import React, { useState } from 'react';
import _ from 'lodash';
import toMask from '../../lib/toMask';
import shareKakao from '../../lib/shareKakao';
import { parseKoDateByDayOfWeek, parseTimeToMinutes } from '../../lib/parseDate';
import CheckoutRequest from './CheckoutRequest';

const InhouseDetails = ({
  isAvailableCheckout,
  bookingItem,
  hotelCode,
  confirmationNo,
}) => {
  const [ isPincodeHidden, setIsPincodeHidden ] = useState(false);
  const [ isCheckoutRequest, setIsCheckoutRequest ] = useState(false);

  const togglePincode = () => {
    setIsPincodeHidden(!isPincodeHidden);
  };

  const openCheckoutRequest = () => {
    setIsCheckoutRequest(true);
  };

  const closeCheckoutRequest = () => {
    setIsCheckoutRequest(false);
  };

  // const onShare = () => {
  //   if (navigator.share) {
  //     navigator.share({
  //       // title: '알펜시아 홀리데이인 리조트',
  //       text: `${bookingItem.hotelName || '-'}\n객실: ${bookingItem.roomNo || '-'}호\n비밀번호: ${bookingItem.pinCode || '-'}*`,
  //       // url: `${process.env.REACT_APP_ALPENSIA_FRONTEND_BASEURL}/?status=CI`,
  //     });
  //   } else if (navigator.clipboard) {
  //     navigator.clipboard.writeText(`${bookingItem.hotelName || '-'}\n객실: ${bookingItem.roomNo || '-'}호\n비밀번호: ${bookingItem.pinCode || '-'}*`);
  //     alert('클립보드에 복사했습니다.');
  //   } else {
  //     alert('공유하기가 지원되지 않는 환경 입니다.');
  //   }
  // }

  return (
    <>
      <div className='booking-background-container'>
        <div className='resort-logo-container'>
          <img className='resort-logo mt-20 mr-20' src='../../assets/images/resort-logo.png' alt='' />
        </div>
        <p className='welcome-message'>
          {`${isAvailableCheckout ? toMask(bookingItem.accompanyName, 1, bookingItem.accompanyName?.length) || '' : bookingItem.accompanyName || ''} 고객님,`}<br/>
          환영합니다.
        </p>
      </div>
      <div className='inhouse-details-card-container'>
        <p className='resort-name'>
          {bookingItem.hotelName || '-'}
        </p>
        <span className='roomtype-name mt-1'>
          {bookingItem.roomTypeName || '-'}
        </span>
        <div className='room-info'>
          <span className='room-name'>
            {`${bookingItem.roomNo || '-'}호`}
          </span>
          <span className='building-name'>
            {`[ ${bookingItem.roomBuilding || '-'} / ${bookingItem.roomFloor || '-'} ]`}
          </span>
        </div>
        <div className='horizontal-line mt-12 mr-30 mb-12 ml-30'></div>
        <div className='period-info'>
          <span className='period-info-label'>
            체크인
          </span>
          <span className='period-info-value'>
            {`${parseKoDateByDayOfWeek(bookingItem.arrivalDate) || '-'} ${parseTimeToMinutes(bookingItem.checkInInfo) || '-'}`}
          </span>
        </div>
        <div className='period-info'>
          <span className='period-info-label'>
            체크아웃
          </span>
          <span className='period-info-value'>
          {`${parseKoDateByDayOfWeek(bookingItem.departureDate) || '-'} ${parseTimeToMinutes(bookingItem.checkOutInfo) || '-'}`}
          </span>
        </div>
        <div className='period-info'>
          <span className='period-info-label'>
            숙박일수
          </span>
          <span className='period-info-value'>
            {`${bookingItem.nights || _.isNumber(bookingItem.nights) ? bookingItem.nights : '-'}박`}
          </span>
        </div>
        <div className='horizontal-box mt-17 mb-17'></div>
        {isAvailableCheckout ? (
          <div className='mt-30 mr-30 ml-30'>
            <button className='basic-button' disabled={bookingItem.comReservationStatus !== 'DUEOUT'} onClick={openCheckoutRequest}>
              체크아웃 요청
            </button>
          </div>
        ) : (
          <>
            <div className='pincode-info'>
              <p className='pincode-info-label'>
                객실 비밀번호
              </p>
              <div className='p-relative mt-9'>
                <span className='pincode-info-value'>
                  {`${isPincodeHidden ? toMask(bookingItem.pinCode, 0, 7) || '' : bookingItem.pinCode || ''} *`}
                </span>
                <img
                  className='password-view p-absolute top-6 right-0'
                  src={`../../assets/images/password-${isPincodeHidden ? 'hidden' : 'show'}.png`}
                  alt=''
                  onClick={togglePincode}
                />
              </div>
            </div>
            {/* <div className='mt-15 mr-30 ml-30'>
              <button className='basic-button' onClick={onShare}>
                비밀번호 공유하기
              </button>
            </div> */}
          </>
        )}
      </div>
      <CheckoutRequest
        isOpen={isCheckoutRequest}
        onClose={closeCheckoutRequest}
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
      />
    </>
  );
};

export default InhouseDetails;