import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Modal from '../commons/Modal';
import Spinner from '../commons/Spinner';
import ErrorPopup from './ErrorPopup';
import CheckoutFailByDestroy from './CheckoutFailByDestroy';
import * as alpensiaApi from '../../api/alpensia';
import Checkbox from '../commons/Checkbox';

const DestroyConfirm = ({
  isOpen,
  onClose,
  hotelCode,
  confirmationNo,
}) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isOpenCheckoutFailByDestroy, setIsOpenCheckoutFailByDestroy ] = useState(false);
  const [ isOpenErrorPopup, setIsOpenErrorPopup ] = useState(false);
  const [ ErrorMessageTitle, setErrorMessageTitle ] = useState('');
  const [ ErrorMessageDescription, setErrorMessageDescription ] = useState('');
  const [ isCheckedYesDestroy, setIsCheckedYesDestroy ] = useState(false);
  const [ isCheckedNoDestroy, setIsCheckedNoDestroy ] = useState(false);

  const { userInfo } = useSelector(state => state.booking);

  const navigate = useNavigate();

  const clearAllChecked = () => {
    setIsCheckedYesDestroy(false);
    setIsCheckedNoDestroy(false);
  };

  const checkYesDestory = (isChecked) => {
    if (isChecked) {
      setIsCheckedYesDestroy(false);
      setIsCheckedNoDestroy(true);
    } else {
      setIsCheckedYesDestroy(true);
      setIsCheckedNoDestroy(false);
    }
  };

  const checkNoDestory = (isChecked) => {
    if (isChecked) {
      setIsCheckedNoDestroy(false);
      setIsCheckedYesDestroy(true);
    } else {
      setIsCheckedNoDestroy(true);
      setIsCheckedYesDestroy(false);
    }
  };

  const openCheckoutFailByDestroy = () => {
    setIsOpenCheckoutFailByDestroy(true);
  };

  const closeCheckoutFailByDestroy = () => {
    setIsOpenCheckoutFailByDestroy(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const requestCheckout = async () => {
    try {
      const { data: responseCheckout } = await alpensiaApi.requestCheckout({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
      });
      if (responseCheckout.resultCode === '1000') navigate('/checkout/success');
      else throw new Error(`${responseCheckout.resultCode}, ${responseCheckout.msg}`);
    } catch (error) {
      setErrorMessageTitle('체크아웃 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const validateAndRequestCheckout = _.debounce(async () => {
    try {
      setIsLoading(true);
      if (isCheckedYesDestroy) openCheckoutFailByDestroy();
      else await requestCheckout();
    } catch (error) {
      console.log('error: ', JSON.stringify(error.message));
    } finally {
      onClose();
      setIsLoading(false);
    }
  }, 300, { maxWait: 1000 });

  useEffect(() => {
    clearAllChecked();
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <p className='checkout-request-title'>
              체크아웃 항목 확인
            </p>
            <span className='checkout-request-description fw-500'>
              객실 내 파손된 기물이 있습니까?
            </span>
            <div className='checkout-agree-checkbox-container'>
              <Checkbox isChecked={isCheckedYesDestroy} onCheck={() => checkYesDestory(isCheckedYesDestroy)} />
              <span className='agree-checkbox-text mr-20'>
                예
              </span>
              <Checkbox isChecked={isCheckedNoDestroy} onCheck={() => checkNoDestory(isCheckedNoDestroy)} />
              <span className='agree-checkbox-text'>
                아니오
              </span>
            </div>
            <button
              className='basic-button h-40 fs-13 mt-30 mb-10'
              disabled={!isCheckedYesDestroy && !isCheckedNoDestroy}
              onClick={validateAndRequestCheckout}
            >
              확인
            </button>
          </>
        )}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <CheckoutFailByDestroy
        isOpen={isOpenCheckoutFailByDestroy}
        onClose={closeCheckoutFailByDestroy}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default DestroyConfirm;