import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import RoomList from '../components/views/RoomList';
import PaymentFail from '../components/views/PaymentFail';
import ErrorPopup from '../components/views/ErrorPopup';
import Spinner from '../components/commons/Spinner';
import { bookingAction } from '../stores/actions';
import * as alpensiaApi from '../api/alpensia';

const RoomSelectContainer = () => {
  const dispatch = useDispatch();
  const { bookingItem, userInfo } = useSelector(state => state.booking);

  const [ isLoading, setIsLoading ] = useState(false);
  const [ availableFacilityList, setAvailableFacilityList ] = useState([]);
  const [ availableBuildingList, setAvailableBuildingList ] = useState([]);
  const [ availableFloorList, setAvailableFloorList ] = useState([]);
  const [ availableRoomList, setAvailableRoomList ] = useState([]);
  const [ selectedRoom, setSelectedRoom ] = useState({});
  const [ isOpenErrorPopup, setIsOpenErrorPopup ] = useState(false);
  const [ ErrorMessageTitle, setErrorMessageTitle ] = useState('');
  const [ ErrorMessageDescription, setErrorMessageDescription ] = useState('');
  const [ isOpenPaymentFailPopup, setIsOpenPaymentFailPopup ] = useState(false);

  const navigate = useNavigate();

  const [ searchParams, setSearchParams ] = useSearchParams();
  const hotelCode = encodeURIComponent(searchParams.get('h'));
  const confirmationNo = encodeURIComponent(searchParams.get('r'));

  const location = useLocation();
  const paymentYn = location.state?.paymentYn;

  const openPaymentFailPopup = () => {
    setIsOpenPaymentFailPopup(true);
  };

  const closePaymentFailPopup = () => {
    setIsOpenPaymentFailPopup(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const getReservation = async () => {
    try {
      const { data: responseGetReservation } = await alpensiaApi.getReservation({ hotelCode, confirmationNo });
      if (responseGetReservation.resultCode === '1000') {
        const { reservInfo, tokenInfo } = responseGetReservation.data;
        dispatch(bookingAction.setBookingItem(reservInfo));
        dispatch(bookingAction.setUserInfo(tokenInfo));
        if (reservInfo.comReservationStatus !== 'DUEIN') navigate(`/?h=${hotelCode}&r=${confirmationNo}`);
        return tokenInfo;
      } else {
        dispatch(bookingAction.setBookingItem({}));
        dispatch(bookingAction.setUserInfo({}));
        throw new Error(`${responseGetReservation.resultCode}, ${responseGetReservation.msg}`);
      }
    } catch (error) {
      setErrorMessageTitle('예약조회 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  }

  const getRoomList = async ({
    grantType,
    accessToken,
  }) => {
    try {
      const { data: responseGetAvailableRoomList } = await alpensiaApi.getAvailableRoomList({
        accessToken: `${grantType} ${accessToken}`,
        hotelCode,
      });
      if (responseGetAvailableRoomList.resultCode === '1000') {
        // 객실명으로 정렬 후, 총 시설정보 저장
        const sortedFacilityList = _.sortBy(responseGetAvailableRoomList.data.availableRoomList, ['roomNo']);
        setAvailableFacilityList(sortedFacilityList);
        // 임의의 객실 선택: 정렬된 객실 중 첫번째 객실
        const tempSelectedRoom = sortedFacilityList[0];
        setSelectedRoom({ ...tempSelectedRoom, isSelected: true });
        // 사용 가능한 동, 층, 객실 목록 설정
        let tempAvailableBuildingList = [];
        let tempAvailableFloorList = [];
        let tempAvailableRoomList = [];
        sortedFacilityList.map((facility) => {
          let existAvailableBuilding = _.find(tempAvailableBuildingList, { name: facility.building });
          if (facility.building === tempSelectedRoom.building) {
            if (existAvailableBuilding) existAvailableBuilding.isSelected = true;
            else tempAvailableBuildingList.push({ name: facility.building, isSelected: true });
            let existAvailableFloor = _.find(tempAvailableFloorList, { name: facility.floor });
            if (facility.floor === tempSelectedRoom.floor) {
              if (existAvailableFloor) existAvailableFloor.isSelected = true;
              else tempAvailableFloorList.push({ name: facility.floor, isSelected: true });
              if (facility.roomNo === tempSelectedRoom.roomNo) tempAvailableRoomList.push({ ...facility, isSelected: true });
              else tempAvailableRoomList.push({ ...facility, isSelected: false });
            } else if (existAvailableFloor) existAvailableFloor.isSelected = false;
              else tempAvailableFloorList.push({ name: facility.floor, isSelected: false });
          } else if (existAvailableBuilding) existAvailableBuilding.isSelected = false;
            else tempAvailableBuildingList.push({ name: facility.building, isSelected: false });
          return facility;
        });
        setAvailableBuildingList(tempAvailableBuildingList);
        setAvailableFloorList(tempAvailableFloorList);
        setAvailableRoomList(tempAvailableRoomList);
      }
      else throw new Error(`${responseGetAvailableRoomList.resultCode}, ${responseGetAvailableRoomList.msg}`);
    } catch (error) {
      setAvailableBuildingList([]);
      setAvailableFloorList([]);
      setAvailableRoomList([]);
      setErrorMessageTitle('객실 목록 조회 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    }
  };

  const changeBuilding = (tempSelectedBuilding) => {
    try {
      setIsLoading(true);
      // 변경하려는 동이 이미 선택되어있는 동이라면 리턴
      if (tempSelectedBuilding.isSelected && tempSelectedBuilding.name === selectedRoom.building) return;
      // 사용 가능한 동 목록 재설정
      let tempAvailableBuildingList = Array.from(availableBuildingList);
      tempAvailableBuildingList.map((building) => {
        // 이전에 선택되어있던 동 상태는 false로, 새로 선택된 동 상태는 true로 변경
        if (building.name === selectedRoom.building) building.isSelected = false;
        if (building.name === tempSelectedBuilding.name) building.isSelected = true;
        return building;
      });
      setAvailableBuildingList(tempAvailableBuildingList);
      // 선택된 객실 변경: 이미 선택된 동 안에 있는 객실 중 첫번째 객실
      const tempSelectedRoom = _.find(availableFacilityList, { building: tempSelectedBuilding.name });
      setSelectedRoom({ ...tempSelectedRoom, isSelected: true });
      // 사용 가능한 층, 객실 목록 재설정
      let tempAvailableFloorList = [];
      let tempAvailableRoomList = [];
      availableFacilityList.map((facility) => {
        if (facility.building === tempSelectedRoom.building) {
          let existAvailableFloor = _.find(tempAvailableFloorList, { name: facility.floor });
          if (facility.floor === tempSelectedRoom.floor) {
            if (existAvailableFloor) existAvailableFloor.isSelected = true;
            else tempAvailableFloorList.push({ name: facility.floor, isSelected: true });
            // 선택된 객실(위에서 변경된 객실) 상태는 true로, 나머지 객실 상태는 false
            if (facility.roomNo === tempSelectedRoom.roomNo) tempAvailableRoomList.push({ ...facility, isSelected: true });
            else tempAvailableRoomList.push({ ...facility, isSelected: false });
          } else if (existAvailableFloor) existAvailableFloor.isSelected = false;
            else tempAvailableFloorList.push({ name: facility.floor, isSelected: false });
        }
        return facility;
      });
      setAvailableFloorList(tempAvailableFloorList);
      setAvailableRoomList(tempAvailableRoomList);
    } catch (error) {
      setErrorMessageTitle('동 선택 실패');
      setErrorMessageDescription('');
      openErrorPopup();
    } finally {
      setIsLoading(false);
    }
  };

  const changeFloor = (tempSelectedFloor) => {
    try {
      setIsLoading(true);
      // 변경하려는 층이 이미 선택되어있는 층이라면 리턴
      if (tempSelectedFloor.isSelected && tempSelectedFloor.name === selectedRoom.floor) return;
      // 사용 가능한 층 목록 재설정
      let tempAvailableFloorList = Array.from(availableFloorList);
      tempAvailableFloorList.map((floor) => {
        // 이전에 선택되어있던 층 상태는 false로, 새로 선택된 층 상태는 true로 변경
        if (floor.name === selectedRoom.floor) floor.isSelected = false;
        if (floor.name === tempSelectedFloor.name) floor.isSelected = true;
        return floor;
      });
      setAvailableFloorList(tempAvailableFloorList);
      // 선택된 객실 변경: 이미 선택된 동과 새로 선택된 층 안에 있는 객실 중 첫번째 객실
      const tempSelectedRoom = _.find(availableFacilityList, { building: selectedRoom.building, floor: tempSelectedFloor.name });
      setSelectedRoom({ ...tempSelectedRoom, isSelected: true });
      // 사용 가능한 객실 목록 재설정
      let tempAvailableRoomList = [];
      availableFacilityList.map((facility) => {
        if (facility.building === tempSelectedRoom.building && facility.floor === tempSelectedRoom.floor) {
          // 선택된 객실(위에서 변경된 객실) 상태는 true로, 나머지 객실 상태는 false
          if (facility.roomNo === tempSelectedRoom.roomNo) tempAvailableRoomList.push({ ...facility, isSelected: true });
          else tempAvailableRoomList.push({ ...facility, isSelected: false });
        }
        return facility;
      });
      setAvailableRoomList(tempAvailableRoomList);
    } catch (error) {
      setErrorMessageTitle('층 선택 실패');
      setErrorMessageDescription('');
      openErrorPopup();
    } finally {
      setIsLoading(false);
    }
  };

  const changeRoom = (tempSelectedRoom) => {
    try {
      setIsLoading(true);
      // 변경하려는 객실이 이미 선택되어있는 객실이라면 리턴
      if (tempSelectedRoom.isSelected && tempSelectedRoom.roomNo === selectedRoom.roomNo) return;
      // 선택된 객실 변경
      setSelectedRoom({ ...tempSelectedRoom, isSelected: true });
      // 사용 가능한 객실 목록 재설정
      let tempAvailableRoomList = Array.from(availableRoomList);
      tempAvailableRoomList.map((room) => {
        // 이전에 선택되어있던 객실 상태는 false로, 새로 선택된 객실 상태는 true로 변경
        if (room.roomNo === selectedRoom.roomNo) room.isSelected = false;
        if (room.roomNo === tempSelectedRoom.roomNo) room.isSelected = true;
        return room;
      });
      setAvailableRoomList(tempAvailableRoomList);
    } catch (error) {
      setErrorMessageTitle('객실 선택 실패');
      setErrorMessageDescription('');
      openErrorPopup();
    } finally {
      setIsLoading(false);
    }
  };

  const roomFixed = async () => {
    try {
      setIsLoading(true);
      const { data: responseRoomFixed } = await alpensiaApi.roomFixed({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
        roomNo: selectedRoom.roomNo,
      });
      if (responseRoomFixed.resultCode === '1000') {
        if (responseRoomFixed.data?.roomFixedInfo?.result?.toUpperCase() === 'OK') return responseRoomFixed.data.roomFixedInfo.payYn;
        else throw new Error(`${responseRoomFixed.data?.roomFixedInfo?.result}, ${responseRoomFixed.data?.roomFixedInfo?.description}`);
      }
      else throw new Error(`${responseRoomFixed.resultCode}, ${responseRoomFixed.msg}`);
    } catch (error) {
      setErrorMessageTitle('객실 선택 실패');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getReservationAndRoomList = async () => {
    try {
      setIsLoading(true);
      const tokenInfo = await getReservation();
      await getRoomList(tokenInfo);
    } catch (error) {
      console.log('error: ', JSON.stringify(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentYn === 'N') openPaymentFailPopup();
    getReservationAndRoomList();
  }, []);

  return (
    <>
      <RoomList
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
        bookingItem={bookingItem}
        availableBuildingList={availableBuildingList}
        availableFloorList={availableFloorList}
        availableRoomList={availableRoomList}
        selectedRoom={selectedRoom}
        changeBuilding={changeBuilding}
        changeFloor={changeFloor}
        changeRoom={changeRoom}
        roomFixed={roomFixed}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <PaymentFail
        isOpen={isOpenPaymentFailPopup}
        onClose={closePaymentFailPopup}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default RoomSelectContainer;