import React from 'react';
import toMask from '../../lib/toMask';

const CheckoutSuccess = ({
  bookingItem,
}) => {
  return (
    <>
      <div className='mr-30 ml-30 pt-40 pb-78'>
        <div className='result-background-container'>
          <img className='resort-sub-logo' src='../../assets/images/resort-sub-logo.png' alt='' />
        </div>
        <div className='horizontal-line mb-40'></div>
        <div className='mr-20 ml-20'>
          <p className='checkout-success-title'>
            체크아웃이 완료 되었습니다.
          </p>
          <span className='checkout-success-desc'>
            {toMask(bookingItem.accompanyName, 1, bookingItem.accompanyName?.length) || ''} 고객님, 감사합니다.<br/>
            안전하고 편안한 귀갓길 되시기 바랍니다.
            항상 보다 나은 서비스로 고객님의 성원에
            보답하겠습니다.
          </span>
          <p className='checkout-success-title mt-15'>
            곧, 객실 정비가 진행될 예정입니다.
          </p>
          <span className='checkout-success-desc'>
            객실을 방문하여 청소 및 점검이 진행될 예정
            이오니, 놓고 가시는 물건이 없도록 유의하여
            주시기 바라며, 정비로 인해 불미스러운 일이
            발생되지 않도록 퇴실을 부탁드립니다.
          </span>
          <span className='checkout-success-desc mt-15'>
            감사합니다.
          </span>
          <span className='checkout-success-desc-notice mt-24 fw-700'>
            ※ 알려드립니다.
          </span>
          <span className='checkout-success-desc-notice'>
            체크아웃 이후 호텔 객실내 미니바 사용 내역이 확인될 경우 미니바 사용금액 결제를 추가로 요청 드릴 수 있으니 이점 양해 부탁 드립니다.
          </span>
        </div>
      </div>
    </>
  );
};

export default CheckoutSuccess;