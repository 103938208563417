import React, { useState } from 'react';
import _ from 'lodash';
import RoomSelect from './RoomSelect';
import PropertyMapDetails from './PropertyMapDetails';

const RoomList = ({
  hotelCode,
  confirmationNo,
  bookingItem,
  availableBuildingList,
  availableFloorList,
  availableRoomList,
  selectedRoom,
  changeBuilding,
  changeFloor,
  changeRoom,
  roomFixed,
}) => {
  const [ isOpenRoomSelect, setIsOpenRoomSelect ] = useState(false);
  const [ isOpenPropertyMap, setIsOpenPropertyMap ] = useState(false);
  const [ payYn, setPayYn ] = useState('');

  const openRoomSelect = _.debounce(async () => {
    try {
      const responsePayYn = await roomFixed();
      setPayYn(responsePayYn);
      setIsOpenRoomSelect(true);
    } catch (error) {
      setIsOpenRoomSelect(false);
      console.log('error: ', JSON.stringify(error.message));
    }
  }, 300, { maxWait: 1000 });

  const closeRoomSelect = () => {
    setIsOpenRoomSelect(false);
  };

  const openPropertyMap = () => {
    setIsOpenPropertyMap(true);
  };

  const closePropertyMap = () => {
    setIsOpenPropertyMap(false);
  };

  return (
    <>
      <p className='room-list-title'>
        객실 선택
      </p>
      <img className='resort-map mt-27' src='../../assets/images/resort-map.png' alt='' />
      <div className='map-zoom-in-button' onClick={openPropertyMap}>
        <img className='search mr-8' src='../../assets/images/search.png' alt='' />
        <span className='map-zoom-in-text'>
          단지 지도 상세보기
        </span>
      </div>
      <p className='room-list roomtype-name'>
        {bookingItem.roomTypeName || '-'}
      </p>
      <span className='room-list-description'>
        고객님께서 원하시는 객실 번호를 선택해 주세요.
      </span>
      <p className='facility-list-title'>
        동 선택
      </p>
      <div className='facility-list-data-container'>
        {availableBuildingList.map((building, index) => (
          <span
            key={index}
            className={`facility-list-value ${building.isSelected ? 'selected': ''}`}
            onClick={() => changeBuilding(building)}
          >
            {building.name || '-'}
          </span>
        ))}
      </div>
      <p className='facility-list-title mt-12'>
        층 선택
      </p>
      <div className='facility-list-data-container'>
        {availableFloorList.map((floor, index) => (
          <span
            key={index}
            className={`facility-list-value ${floor.isSelected ? 'selected': ''}`}
            onClick={() => changeFloor(floor)}
          >
            {floor.name || '-'}
          </span>
        ))}
      </div>
      <p className='facility-list-title mt-12'>
        객실 선택
      </p>
      <div className='facility-list-data-container'>
        {availableRoomList.map((room, index) => (
          <span
            key={index}
            className={`facility-list-value ${room.isSelected ? 'selected': ''}`}
            onClick={() => changeRoom(room)}
          >
            {`${room.roomNo || '-'}호`}
          </span>
        ))}
      </div>
      <div className='pr-30 pb-78 pl-30'>
        <button className='basic-button d-block mt-35' disabled={!selectedRoom || _.isEmpty(selectedRoom)} onClick={openRoomSelect}>
          확인
        </button>
      </div>
      <RoomSelect
        isOpen={isOpenRoomSelect}
        onClose={closeRoomSelect}
        data={selectedRoom}
        payYn={payYn}
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
      />
      <PropertyMapDetails
        isOpen={isOpenPropertyMap}
        onClose={closePropertyMap}
        hotelCode={bookingItem.hotelCode}
      />
    </>
  );
};

export default RoomList;