import * as types from '../constants';

const initialState = {
  queryStringParams: {},
  bookingItem: {},
  hotelInfo: {},
  roomInfo: {},
  userInfo: {},
};

const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BOOKING_QUERY_STRING_PARAMS:
      return {
        ...state,
        queryStringParams: action.payload,
      };
    case types.SET_BOOKING_ITEM:
      return {
        ...state,
        bookingItem: action.payload,
      };
    case types.SET_HOTEL_INFO:
      return {
        ...state,
        hotelInfo: action.payload,
      };
    case types.SET_ROOM_INFO:
      return {
        ...state,
        roomInfo: action.payload,
      };
    case types.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};

export default BookingReducer;