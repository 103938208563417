import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from '../components/commons/Spinner';
import * as alpensiaApi from '../api/alpensia';

const CertificationResultContainer = () => {
  const [ isLoading, setIsLoading ] = useState(false);

  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const hotelCode = encodeURIComponent(searchParams.get('h'));
  const confirmationNo = encodeURIComponent(searchParams.get('r'));
  const token = searchParams.get('token');
  const reqNum = searchParams.get('reqNum');
  const retInfo = searchParams.get('retInfo');

  const addAccompanyGuest = async () => {
    try {
      setIsLoading(true);
      const { data: responseAddAccompanyGuest } = await alpensiaApi.addAccompanyGuest({
        accessToken: `Bearer ${token}`,
        reqNum,
        retInfo,
      });
      if (responseAddAccompanyGuest.resultCode === '1000') navigate(`/terms_of_use/?h=${hotelCode}&r=${confirmationNo}`, { replace: true });
      else throw new Error(`${responseAddAccompanyGuest.resultCode}, ${responseAddAccompanyGuest.msg}`);
    } catch (error) {
      navigate(`/?h=${hotelCode}&r=${confirmationNo}`, { 
        state: {
          certificateYn: 'N',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (token && reqNum && retInfo) addAccompanyGuest();
    else navigate(`/?h=${hotelCode}&r=${confirmationNo}`, { 
      state: {
        certificateYn: 'N',
      },
    });
  }, []);

  return (
    <>
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default CertificationResultContainer;