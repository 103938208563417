import React from 'react';

const Checkbox = ({
  isChecked,
  onCheck,
}) => {
  const setImg = `../assets/images/${isChecked ? 'checked' : 'unchecked'}.png`;

  return (
    <img className='checkbox mr-8' src={setImg} alt='' onClick={onCheck} />
  );
};

export default Checkbox;