import React from 'react';
import Modal from '../commons/Modal';

const PropertyMapDetails = ({
  isOpen,
  onClose,
  hotelCode,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={true}
        onClose={onClose}
        title='단지 지도 상세보기'
        size='x-large'
        Content={(
          <>
            <div className='h-full pb-100'>
              <iframe src={`https://work.alpensia.net/alpmap.asp?resort=${hotelCode?.toUpperCase()}`} width='100%' height='100%' />
            </div>
          </>
        )}
      />
    </>
  );
};

export default PropertyMapDetails;