const toMask = (original, startNumber, endNumber) => {
  if (!original) return original;
  const splited = original.split('');
  splited.map((v, index) => {
    if (index < startNumber || index >= endNumber -1) return;
    splited[index] = '*';
  });
  return splited.join('');
};

export default toMask;