const shareKakao = (roomNo, pincode) => {
  const { Kakao } = window;
  if (!Kakao.isInitialized()) Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);

  Kakao.Link.sendDefault({
    objectType: 'feed',
    content: {
      title: '알펜시아 홀리데이인 리조트',
      description: `객실: ${roomNo}호\n비밀번호: ${pincode}*`,
      imageUrl: 'https://imgate-site-images.s3.ap-northeast-2.amazonaws.com/alpensia-background.png',
      link: {
        mobileWebUrl: 'http://maul.teamoim.ga:23001/',
      },
    },
  });
};

export default shareKakao;