import React, { useState, useEffect } from 'react';
import ErrorPopup from './ErrorPopup';

const CheckinRevisit = () => {
  const [ isOpenErrorPopup, setIsOpenErrorPopup ] = useState(false);
  const [ ErrorMessageTitle, setErrorMessageTitle ] = useState('');
  const [ ErrorMessageDescription, setErrorMessageDescription ] = useState('');

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  useEffect(() => {
    setErrorMessageTitle('체크인 완료');
    setErrorMessageDescription('체크인이 완료된 예약입니다.');
    openErrorPopup();
  }, []);

  return (
    <>
      <div className='mr-30 ml-30 pt-40 pb-78'>
        <div className='result-background-container'>
          <img className='resort-sub-logo' src='../../assets/images/resort-sub-logo.png' alt='' />
        </div>
        <div className='horizontal-line mb-40'></div>
        <div className='mr-20 ml-20'>
          <p className='checkout-success-title'>
            객실 및 핀코드 정보는<br/>
            문자메시지를<br/>
            확인하여 주시기 바랍니다.
          </p>
          <span className='checkout-success-desc'>
            문자메시지 확인이 불가능한 경우,<br/>
            프론트를 방문하여 주세요.
          </span>
        </div>
      </div>
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
    </>
  );
};

export default CheckinRevisit;