import React from 'react';
import Modal from '../commons/Modal';

const PaymentFail = ({
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <p className='payment-fail-title'>
              결제 실패
            </p>
            <span className='payment-fail-description'>
              결제 승인에 실패하였습니다.<br/>
              다시 시도하여 주세요.
            </span>
            <button className='basic-button h-40 fs-13 mt-20 mb-2' onClick={onClose}>
              확인
            </button>
          </>
        )}
      />
    </>
  );
};

export default PaymentFail;