import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import Modal from "../commons/Modal";
import ErrorPopup from "../views/ErrorPopup";
import MiniBarConfirm from "../views/MiniBarConfirm";
import Spinner from "../commons/Spinner";
import * as alpensiaApi from "../../api/alpensia";
import DestroyConfirm from "./DestroyConfirm";

// const responseCheckBalance = {
//   resultCode: "1000",
//   data: {
//     balanceInfo: {
//       resvNameId: "1",
//       currentBalance: "0",
//       totalCharges: "0",
//       currencyCode: "kr",
//     },
//   },
// };

// const bookingItem = {
//   hotelCode: "KAGPR",
// };

const CheckoutRequest = ({ isOpen, onClose, hotelCode, confirmationNo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenMiniBarConfirm, setIsOpenMiniBarConfirm] = useState(false);
  const [isOpenDestroyConfirm, setIsOpenDestroyConfirm] = useState(false);
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [ErrorMessageTitle, setErrorMessageTitle] = useState("");
  const [ErrorMessageDescription, setErrorMessageDescription] = useState("");

  const { bookingItem, userInfo } = useSelector((state) => state.booking);

  const navigate = useNavigate();

  const openMiniBarConfirm = () => {
    setIsOpenMiniBarConfirm(true);
  };

  const closeMiniBarConfirm = () => {
    setIsOpenMiniBarConfirm(false);
  };

  const openDestroyConfirm = () => {
    setIsOpenDestroyConfirm(true);
  };

  const closeDestroyConfirm = () => {
    setIsOpenDestroyConfirm(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const checkBalance = async () => {
    try {
      const { data: responseCheckBalance } = await alpensiaApi.checkBalance({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
      });
      if (responseCheckBalance.resultCode === "1000") {
        // 밸런스가 0이 아닌 경우: 프론트 방문 요청
        if (responseCheckBalance.data.balanceInfo.currentBalance > 0) {
          setErrorMessageTitle("프론트 방문 요청");
          setErrorMessageDescription(
            "미정산된 결제내역이 있습니다.\n프런트를 방문하여 체크아웃을 진행해 주세요."
          );
          openErrorPopup();
          return false;
        }
        // 밸런스가 0인 경우: 리턴 (체크아웃 가능)
        else return true;
      } else
        throw new Error(
          `${responseCheckBalance.resultCode}, ${responseCheckBalance.msg}`
        );
    } catch (error) {
      setErrorMessageTitle("밸런스 체크 실패");
      setErrorMessageDescription(
        `${error.message ? error.message : JSON.stringify(error)}`
      );
      openErrorPopup();
      throw error;
    }
  };

  const requestCheckout = async () => {
    try {
      const { data: responseCheckout } = await alpensiaApi.requestCheckout({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
      });
      if (responseCheckout.resultCode === "1000") navigate("/checkout/success");
      else
        throw new Error(
          `${responseCheckout.resultCode}, ${responseCheckout.msg}`
        );
    } catch (error) {
      setErrorMessageTitle("체크아웃 실패");
      setErrorMessageDescription(
        `${error.message ? error.message : JSON.stringify(error)}`
      );
      openErrorPopup();
      throw error;
    }
  };

  const validateAndRequestCheckout = _.debounce(
    async () => {
      try {
        setIsLoading(true);
        const resultCode = await checkBalance();
        // 인터컨티넨탈(kagha)이면, 미니바 사용, 객실 파손여부 확인
        if (resultCode && bookingItem.hotelCode?.toUpperCase() === "KAGHA")
          openMiniBarConfirm();
        // 홀리데이인리조트(kagpr)이면, 객실 파손여부 확인
        else if (resultCode && bookingItem.hotelCode?.toUpperCase() === "KAGPR")
          openDestroyConfirm();
        // 홀리데이인스위트(kagps)이면, 체크아웃 요청
        else if (resultCode && bookingItem.hotelCode?.toUpperCase() === "KAGPS")
          await requestCheckout();
      } catch (error) {
        console.log("error: ", JSON.stringify(error.message));
      } finally {
        onClose();
        setIsLoading(false);
      }
    },
    300,
    { maxWait: 1000 }
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={
          <>
            <p className="checkout-request-title">체크아웃을 하시겠습니까?</p>
            <span className="checkout-request-description">
              체크아웃 시 해당 객실에 재입장 및 객실 비밀번호 사용이 불가능
              합니다.
            </span>
            <button
              className="basic-button h-40 fs-13 mt-20"
              onClick={validateAndRequestCheckout}
            >
              체크아웃 하기
            </button>
            <button
              className="basic-button h-40 fs-13 mt-20 mb-2 bc-gray"
              onClick={onClose}
            >
              닫기
            </button>
          </>
        }
      />
      <MiniBarConfirm
        isOpen={isOpenMiniBarConfirm}
        onClose={closeMiniBarConfirm}
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
      />
      <DestroyConfirm
        isOpen={isOpenDestroyConfirm}
        onClose={closeDestroyConfirm}
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default CheckoutRequest;
