import React from 'react';
import Modal from '../commons/Modal';

const CheckoutFailByDestroy = ({
  isOpen,
  onClose,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <p className='payment-fail-title'>
              모바일 체크아웃 불가
            </p>
            <span className='payment-fail-description'>
              <span className='fw-700'>파손된 기물</span>이 있는 경우,<br/><span className='c-red'>프론트를 방문</span>하여 체크아웃을 완료하여 주세요.<br/><br/>
              <span className='fw-700'>해당 사항이 없는 경우,</span><br/> <span className='c-red'>모바일 체크아웃을 다시 진행</span>하여 주세요.
            </span>
            <button className='basic-button h-40 fs-13 mt-20 mb-2' onClick={onClose}>
              확인
            </button>
          </>
        )}
      />
    </>
  );
};

export default CheckoutFailByDestroy;