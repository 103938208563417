import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BookingDetails from "../components/views/BookingDetails";
import InhouseDetails from "../components/views/InhouseDetails";
import ErrorPopup from "../components/views/ErrorPopup";
import Spinner from "../components/commons/Spinner";
import { bookingAction } from "../stores/actions";
import * as alpensiaApi from "../api/alpensia";

// const responseGetReservation = {
//   resultCode: "1000",
//   msg: "OK",
//   data: {
//     reservInfo: {
//       lastName: "Choi",
//       nativeName: "최필규",
//       roomTypeCode: "XSTN",
//       checkInInfo: "1500",
//       comReservationStatus: "DUEOUT",
//       allowCheckInYn: true,
//       accompanyPhone: null,
//       pgMid: "T5102001",
//       roomFloor: null,
//       phoneNo: null,
//       accompanyName: null,
//       roomStatus: null,
//       checkOutInfo: "1100",
//       departureDate: "20230318",
//       totalCurrencyCode: "KRW",
//       roomRoutingYn: false,
//       roomNo: null,
//       roomTypeName: "스탠다드 (22)",
//       hotelCode: "KAGPS",
//       hotelName: "홀리데이인 스위트츠",
//       arrivalDate: "20230720",
//       firstName: "Pil Kyu",
//       totalAmount: 0.0,
//       nights: 1,
//       pinCode: null,
//       confirmationNo: "18878695",
//       roomBuilding: null,
//       getRoomListYn: true,
//     },
//     tokenInfo: {
//       grantType: "Bearer",
//       accessToken:
//         "eyJyIjoiTExkdlU4c053M0NvY0hvOFZFeHRDQSUzRCUzRCIsImgiOiJGJTJGUkVKbVpDODIwJTNEIiwiYWxnIjoiSFMyNTYifQ.eyJhdXRoIjoibW9iaWxlIiwiZXhwIjoxNjg5NjQ0MTkzfQ.HSefCo4Z7lK1EFf8sjgnKakn_zTJnHgAyKJ5cbnLoOM",
//       accessExpiration: 1689644193021,
//       refreshToken:
//         "eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODk2NTMxOTN9.O62hDzfsj1uJ_3FlhKOkGik9VsouKSVGLRyybYB1tyU",
//       refreshExpiration: 1689653193021,
//     },
//   },
//   timeZone: "Asia/Seoul",
//   timeStamp: 1689642393023,
// };

const CheckoutRequestContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [ErrorMessageTitle, setErrorMessageTitle] = useState("");
  const [ErrorMessageDescription, setErrorMessageDescription] = useState("");

  const dispatch = useDispatch();
  const { bookingItem } = useSelector((state) => state.booking);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const hotelCode = encodeURIComponent(searchParams.get("h"));
  const confirmationNo = encodeURIComponent(searchParams.get("r"));

  const { history } = window;

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const getReservation = async () => {
    try {
      setIsLoading(true);
      const { data: responseGetReservation } = await alpensiaApi.getReservation(
        { hotelCode, confirmationNo }
      );
      if (responseGetReservation.resultCode === "1000") {
        const { reservInfo, tokenInfo } = responseGetReservation.data;
        dispatch(bookingAction.setBookingItem(reservInfo));
        dispatch(bookingAction.setUserInfo(tokenInfo));
        if (reservInfo.comReservationStatus === "CHECKEDOUT")
          navigate("/checkout/success");
        else if (reservInfo.comReservationStatus === "CANCELED") {
          setErrorMessageTitle("취소된 예약");
          setErrorMessageDescription(
            "취소된 예약입니다.\n문의사항이 있으시면 프론트를 방문해주세요."
          );
          openErrorPopup();
        } else if (
          ["RESERVED", "DUEIN", "INHOUSE", "NOSHOW"].indexOf(
            reservInfo.comReservationStatus
          ) >= 0
        ) {
          setErrorMessageTitle("체크아웃 불가");
          setErrorMessageDescription(
            "체크아웃 예정 날짜가 아닙니다.\n문의사항이 있으시면 프론트를 방문해주세요."
          );
          openErrorPopup();
        }
      } else {
        dispatch(bookingAction.setBookingItem({}));
        dispatch(bookingAction.setUserInfo({}));
        throw new Error(
          `${responseGetReservation.resultCode}, ${responseGetReservation.msg}`
        );
      }
    } catch (error) {
      setErrorMessageTitle("예약조회 실패");
      setErrorMessageDescription(
        `${error.message ? error.message : JSON.stringify(error)}`
      );
      openErrorPopup();
    } finally {
      setIsLoading(false);
    }
  };

  const preventGoBack = () => {
    history.pushState(null, "", location.href);
  };

  useEffect(() => {
    getReservation();
  }, []);

  useEffect(() => {
    (() => {
      history.pushState(null, "", location.href);
      window.addEventListener("popstate", preventGoBack);
    })();

    return () => {
      window.removeEventListener("popstate", preventGoBack);
    };
  }, []);

  return (
    <>
      {["INHOUSE", "DUEOUT"].indexOf(bookingItem.comReservationStatus) >= 0 ? (
        <InhouseDetails
          isAvailableCheckout={true}
          bookingItem={bookingItem}
          hotelCode={hotelCode}
          confirmationNo={confirmationNo}
        />
      ) : (
        <BookingDetails
          isAvailableCheckout={true}
          bookingItem={bookingItem}
          getCertification={() => ""}
        />
      )}
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <Spinner isLoading={isLoading} />
    </>
  );
};

export default CheckoutRequestContainer;
