import React from 'react';
import Modal from '../commons/Modal';

const CertificationFail = ({
  isOpen,
  onClose,
  reCertification,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <p className='payment-fail-title'>
              본인인증 실패
            </p>
            <span className='payment-fail-description'>
              본인 인증에 실패 하였습니다.<br/>
              다시 확인해 주세요.
            </span>
            <button className='basic-button h-40 fs-13 mt-20' onClick={reCertification}>
              다시 확인 하기
            </button>
            <button className='basic-button h-40 fs-13 mt-20 mb-2 bc-gray' onClick={onClose}>
              닫기
            </button>
          </>
        )}
      />
    </>
  );
};

export default CertificationFail;