import React from 'react';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import toMask from '../../lib/toMask';
import parseToAmount from '../../lib/parseToAmount';
import { parseEnDateByDayOfWeek } from '../../lib/parseDate';

const BookingDetails = ({
  isAvailableCheckout,
  bookingItem,
  getCertification,
}) => {
  const toMaskByPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    const onlyNumber = phoneNumber.replaceAll('-', '');
    return toMask(onlyNumber, onlyNumber.length - 8, onlyNumber.length - 3);
  };

  const validateCertification = () => {
    const isDisabledCheckinDate = momentTimezone.tz(`${bookingItem.arrivalDate}T${bookingItem.checkInInfo || '1500'}`, bookingItem.timeZone).valueOf() > bookingItem.timeStamp;
    const isDisabledBookingStatus = bookingItem.comReservationStatus !== 'DUEIN';
    const isDisabledAllowCheckInYn = bookingItem.allowCheckInYn === false;
    return isDisabledCheckinDate || isDisabledBookingStatus || isDisabledAllowCheckInYn || isAvailableCheckout;
  };

  return (
    <>
      <div className='booking-background-container'>
        <div className='resort-logo-container'>
          <img className='resort-logo mt-20 mr-20' src='../../assets/images/resort-logo.png' alt='' />
        </div>
      </div>
      <div className='booking-details-card-container'>
        <p className='resort-name'>
          {bookingItem.hotelName || '-'}
        </p>
        <span className='roomtype-name mt-8'>
          {bookingItem.roomTypeName || '-'}
        </span>
        <span className='period'>
          {`${parseEnDateByDayOfWeek(bookingItem.arrivalDate) || ''} - ${parseEnDateByDayOfWeek(bookingItem.departureDate) || ''} | ${bookingItem.nights || _.isNumber(bookingItem.nights) ? bookingItem.nights : '-'} Night`}
        </span>
        <span className='amount'>
          {`결제 예정 금액: ${parseToAmount(bookingItem.totalAmount)}원`}
        </span>
        <div className='horizontal-line mt-10 mr-30 mb-10 ml-30'></div>
        <p className='booking-details-title'>
          예약자 정보
        </p>
        <div className='booking-details-info mt-8'>
          <span className='booking-details-info-label'>
            Conf.No
          </span>
          <span className='booking-details-info-value'>
            {bookingItem.confirmationNo || '-'}
          </span>
        </div>
        <div className='booking-details-info'>
          <span className='booking-details-info-label'>
            Last Name
          </span>
          <span className='booking-details-info-value'>
            {toMask(bookingItem.lastName, 1, bookingItem.lastName?.length) || '-'}
          </span>
        </div>
        <div className='booking-details-info'>
          <span className='booking-details-info-label'>
            First Name
          </span>
          <span className='booking-details-info-value'>
            {toMask(bookingItem.firstName, 1, bookingItem.firstName?.length) || '-'}
          </span>
        </div>
        <div className='booking-details-info'>
          <span className='booking-details-info-label'>
            Phone
          </span>
          <span className='booking-details-info-value'>
            {toMaskByPhoneNumber(bookingItem.phoneNo) || '-'}
          </span>
        </div>
        <div className='horizontal-box mt-18 mb-8'></div>
        <p className='booking-details-title'>
          투숙객 정보 인증
        </p>
        <div className='certification-notice'>
          <img className='important-notice mt-5 mr-23 ml-23' src='../../assets/images/important-notice.png' alt='' />
          <span className='certification-notice-text'>
            본인 인증시 실제 투숙객의 정보를<br/>
            입력하여 본인 인증을 진행하여 주세요.
          </span>
        </div>
        <div className='mr-30 ml-30'>
          <button className='basic-button' disabled={validateCertification()} onClick={getCertification}>
            본인인증 후 체크인하기
          </button>
        </div>
      </div>
    </>
  );
};

export default BookingDetails;