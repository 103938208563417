import React from 'react';
import { useLocation } from 'react-router-dom';

const ModalTermsOfUseDetails = () => {
  const location = useLocation();

  const title = location.state?.title;
  const description = location.state?.description;

  return (
    <>
      <div className='pb-120'>
        <p className='terms-of-use-details-title'>
          {title || '-'}
        </p>
        <pre className='terms-of-use-details-description'>
          {description || '-'}
        </pre>
      </div>
    </>
  );
};

export default ModalTermsOfUseDetails;