import React from 'react';
import { useSelector } from 'react-redux';

const CheckinBefore = () => {
  const { bookingItem } = useSelector(state => state.booking);

  return (
    <>
      <div className='mr-30 ml-30 pt-40 pb-78'>
        <div className='result-background-container'>
          <img className='resort-sub-logo' src='../../assets/images/resort-sub-logo.png' alt='' />
        </div>
        <div className='horizontal-line mb-40'></div>
        <div className='mr-20 ml-20'>
          <p className='checkin-before-title'>
            체크인은 {bookingItem.checkInInfo?.slice(0, 2) || '15'}시 이후부터<br/>
            가능합니다.
          </p>
          <span className='checkin-before-desc'>
            {bookingItem.checkInInfo?.slice(0, 2) || '15'}시 이전 체크인을 원하시는 고객님은<br/>
            프런트에 방문하여 주세요.
          </span>
        </div>
      </div>
    </>
  );
};

export default CheckinBefore;