import * as types from '../constants';

export const setBookingQueryStringParams = queryStringParams => ({
  type: types.SET_BOOKING_QUERY_STRING_PARAMS,
  payload: queryStringParams
});

export const setBookingItem = bookingItem => ({
  type: types.SET_BOOKING_ITEM,
  payload: bookingItem
});

export const setHotelInfo = hotelInfo => ({
  type: types.SET_HOTEL_INFO,
  payload: hotelInfo
});

export const setRoomInfo = roomInfo => ({
  type: types.SET_ROOM_INFO,
  payload: roomInfo
});

export const setUserInfo = userInfo => ({
  type: types.SET_USER_INFO,
  payload: userInfo
});