import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { parseUnixTimestampToSeconds } from '../../lib/parseDate';
import Modal from '../commons/Modal';

const PaymentSuccess = ({
  isOpen,
  onClose,
  data,
  hotelCode,
  confirmationNo,
}) => {
  const navigate = useNavigate();

  const parseInstallment = (installment) => {
    if (!_.isNumber(installment)) return installment;
    if (installment === 0) return '일시불';
    return `${installment}개월`;
  };

  const moveToInhouseDetail = () => {
    navigate(`/?h=${hotelCode}&r=${confirmationNo}`, {
      state: {
        visitedConfirmationNo: confirmationNo,
      },
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <>
            <p className='payment-success-title'>
              결제가 완료 되었습니다.
            </p>
            <div className='payment-success-details-info mt-20'>
              <span className='payment-success-details-info-label'>
                거래유형
              </span>
              <span className='payment-success-details-info-value'>
                {data.cardName || '-'}
              </span>
            </div>
            <div className='payment-success-details-info'>
              <span className='payment-success-details-info-label'>
                할부기간
              </span>
              <span className='payment-success-details-info-value'>
                {parseInstallment(data.cardQuota) || '-'}
              </span>
            </div>
            <div className='payment-success-details-info'>
              <span className='payment-success-details-info-label'>
                승인번호
              </span>
              <span className='payment-success-details-info-value'>
                {data.pgTid || '-'}
              </span>
            </div>
            <div className='payment-success-details-info'>
              <span className='payment-success-details-info-label'>
                결제일
              </span>
              <span className='payment-success-details-info-value'>
                {parseUnixTimestampToSeconds(data.paidAt) || '-'}
              </span>
            </div>
            <button className='basic-button h-40 fs-13 mt-20 mb-2' onClick={moveToInhouseDetail}>
              확인
            </button>
          </>
        )}
      />
    </>
  );
};

export default PaymentSuccess;