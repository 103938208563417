import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import BookingDetails from '../components/views/BookingDetails';
import SafariSetup from '../components/views/SafariSetup';
import InhouseDetails from '../components/views/InhouseDetails';
import CertificationFail from '../components/views/CertificationFail';
import ErrorPopup from '../components/views/ErrorPopup';
import Spinner from '../components/commons/Spinner';
import { bookingAction } from '../stores/actions';
import * as alpensiaApi from '../api/alpensia';

const BookingFetchContainer = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isOpenErrorPopup, setIsOpenErrorPopup ] = useState(false);
  const [ ErrorMessageTitle, setErrorMessageTitle ] = useState('');
  const [ ErrorMessageDescription, setErrorMessageDescription ] = useState('');
  const [ isOpenSafariSetup, setIsOpenSafariSetup ] = useState(false);
  const [ isOpenCertificationFailPopup, setIsOpenCertificationFailPopup ] = useState(false);

  const dispatch = useDispatch();
  const { bookingItem, userInfo } = useSelector(state => state.booking);

  const navigate = useNavigate();

  const location = useLocation();
  const certificateYn = location.state?.certificateYn;
  const visitedConfirmationNo = location.state?.visitedConfirmationNo;

  const [ searchParams, setSearchParams ] = useSearchParams();
  const hotelCode = encodeURIComponent(searchParams.get('h'));
  const confirmationNo = encodeURIComponent(searchParams.get('r'));

  const isIphone = navigator.userAgent.toLowerCase().indexOf('iphone') >= 0;
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') >= 0 && navigator.userAgent.toLowerCase().indexOf('crios') < 0;

  const { history } = window;

  const openSafariSetup = () => {
    setIsOpenSafariSetup(true);
  };

  const closeSafariSetup = () => {
    setIsOpenSafariSetup(false);
  };

  const openCertificationFailPopup = () => {
    setIsOpenCertificationFailPopup(true);
  };

  const closeCertificationFailPopup = () => {
    setIsOpenCertificationFailPopup(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const getReservation = async () => {
    try {
      setIsLoading(true);
      const { data: responseGetReservation } = await alpensiaApi.getReservation({ hotelCode, confirmationNo });
      if (responseGetReservation.resultCode === '1000') {
        const { reservInfo, tokenInfo } = responseGetReservation.data;
        dispatch(bookingAction.setBookingItem({
          ...reservInfo,
          timeZone: responseGetReservation.timeZone,
          timeStamp: responseGetReservation.timeStamp,
        }));
        dispatch(bookingAction.setUserInfo(tokenInfo));
        const isAvailableCheckinNow = momentTimezone.tz(`${reservInfo.arrivalDate}T${reservInfo.checkInInfo || '1500'}`, responseGetReservation.timeZone).valueOf() < responseGetReservation.timeStamp;
        const isEqualConfirmationNo = confirmationNo === visitedConfirmationNo;
        switch (reservInfo.comReservationStatus) {
          case 'RESERVED':
            setErrorMessageTitle('체크인 불가');
            setErrorMessageDescription('체크인 가능 예약이 아닙니다.\n프론트를 방문해주세요.');
            openErrorPopup();
            return;
          case 'DUEIN':
            if (!isAvailableCheckinNow){
              navigate('/checkin/standby'); 
            } 
            else if(reservInfo.allowCheckInYn === false){
              setErrorMessageTitle('체크인 불가');
              setErrorMessageDescription('체크인 가능 예약이 아닙니다.\n프론트를 방문해주세요.');
              openErrorPopup();
            }
            else if (isIphone && isSafari){
              openSafariSetup();
            }
            return;
          case 'INHOUSE':
            if (!isEqualConfirmationNo) navigate('/checkin/revisit');
            return;
          case 'DUEOUT':
            if (!isEqualConfirmationNo) navigate('/checkin/revisit');
            return;
          case 'CHECKEDOUT':
            return navigate('/checkout/success');
          case 'CANCELED':
            setErrorMessageTitle('취소된 예약');
            setErrorMessageDescription('취소된 예약입니다.\n문의사항이 있으시면 프론트를 방문해주세요.');
            openErrorPopup();
            return;
          case 'NOSHOW':
            setErrorMessageTitle('체크인 불가');
            setErrorMessageDescription('체크인 가능 시간이 아닙니다.\n프론트를 방문해주세요.');
            openErrorPopup();
            return;
          default:
            return;
        }
      } else throw new Error(`${responseGetReservation.resultCode}, ${responseGetReservation.msg}`);
    } catch (error) {
      dispatch(bookingAction.setBookingItem({}));
      dispatch(bookingAction.setUserInfo({}));
      setErrorMessageTitle('예약조회에 실패 하였습니다');
      setErrorMessageDescription(`${error.message ? error.message : JSON.stringify(error)}`);
      openErrorPopup();
      console.log(error);
      //throw error;
    } finally {
      setIsLoading(false);
    }
  }

  const getCertification = _.debounce(async () => {
    try {
      setIsLoading(true);
      const { data: responseGetCertification } = await alpensiaApi.getCertification({
        accessToken: `${userInfo.grantType} ${userInfo.accessToken}`,
        hotelCode,
        confirmationNo,
      });
      if (responseGetCertification.resultCode === '1000') {
        const { reqNum, reqInfo } = responseGetCertification.data.certInfo;
        const certificationForm = document.certificationForm;
        certificationForm.reqInfo.value = reqInfo;
        certificationForm.retUrl.value = `31${process.env.REACT_APP_ALPENSIA_FRONTEND_BASEURL}/certification/result/?token=${userInfo.accessToken}&h=${hotelCode}&r=${confirmationNo}&reqNum=${reqNum}`;
        certificationForm.verSion.value = '2';
        certificationForm.action = 'https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp';
        certificationForm.target = 'PCCV3Window';
        certificationForm.submit();
      }
      else throw new Error(`${responseGetCertification.resultCode}, ${responseGetCertification.msg}`);
    } catch (error) {
      openCertificationFailPopup();
    } finally {
      setIsLoading(false);
    }
  }, 300, { maxWait: 1000 });

  const preventGoBack = () => {
    history.pushState(null, '', location.href);
  };

  useEffect(() => {
    if (certificateYn === 'N') openCertificationFailPopup();
    getReservation();
  }, []);

  useEffect(() => {
    (() => {
      history.pushState(null, '', location.href);
      window.addEventListener('popstate', preventGoBack);
    })();

    return () => {
      window.removeEventListener('popstate', preventGoBack);
    };
  },[]);

  return (
    <>
      {['INHOUSE', 'DUEOUT'].indexOf(bookingItem.comReservationStatus) >= 0 ? (
        <InhouseDetails
          bookingItem={bookingItem}
          hotelCode={hotelCode}
          confirmationNo={confirmationNo}
        />
      ) : (
        <BookingDetails
          bookingItem={bookingItem}
          getCertification={getCertification}
        />
      )}
      <SafariSetup
        isOpen={isOpenSafariSetup}
        onClose={closeSafariSetup}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <CertificationFail
        isOpen={isOpenCertificationFailPopup}
        onClose={closeCertificationFailPopup}
        reCertification={getCertification}
      />
      <form id='certificationForm' name='certificationForm' method='post'>
        <input type='hidden' id='reqInfo' name='reqInfo' />
        <input type='hidden' id='retUrl' name='retUrl' />
        <input type='hidden' id='verSion' name='verSion' />
      </form>
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default BookingFetchContainer;