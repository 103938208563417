import './assets/css/index.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ScrollToTop from './components/commons/ScrollToTop';
import PagePC from './components/views/PagePC';
import Page404 from './components/views/Page404';
import BookingFetch from './containers/BookingFetchContainer';
import CheckinBefore from './components/views/CheckinBefore';
import CheckinRevisit from './components/views/CheckinRevisit';
import CertificationResult from './containers/CertificationResultContainer';
import PaymentResult from './containers/PaymentResultContainer';
import TermsOfUseList from './containers/TermsOfUseListContainer';
import TermsOfUseDetails from './components/views/TermsOfUseDetails';
import RoomSelect from './containers/RoomSelectContainer';
import CheckoutRequest from './containers/CheckoutRequestContainer';
import CheckoutSuccess from './containers/CheckoutSuccessContainer';
import { store, persistor } from './stores';

const App = () => {
  const isMobile = () => {
    const pc = 'win16|win32|win64|mac|macintel';
    if (navigator.platform) {
      if (pc.indexOf(navigator.platform.toLowerCase()) < 0) return true;
      else return false;
    }
    else return true;
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop />
          <Routes>
            {isMobile() ? (
              <>
                <Route path='/' element={<BookingFetch />}/>
                <Route path='/checkin/standby' element={<CheckinBefore />}/>
                <Route path='/certification/result' element={<CertificationResult />}/>
                <Route path='/payment/result' element={<PaymentResult />}/>
                <Route path='/terms_of_use' element={<TermsOfUseList />}/>
                <Route path='/terms_of_use/details' element={<TermsOfUseDetails />}/>
                <Route path='/room' element={<RoomSelect />}/>
                <Route path='/checkin/revisit' element={<CheckinRevisit />}/>
                <Route path='/checkout' element={<CheckoutRequest />}/>
                <Route path='/checkout/success' element={<CheckoutSuccess />}/>
                <Route path='*' element={<Page404 />} />
              </>
            ) : (
              <Route path='*' element={<PagePC />}/>
            )}
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
