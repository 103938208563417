import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import toMask from '../../lib/toMask';
import { parseEnDateByDayOfWeek } from '../../lib/parseDate';
import parseToAmount from '../../lib/parseToAmount';
import Checkbox from '../commons/Checkbox';

const TermsOfUseList = ({
  bookingItem,
  termsOfUseList,
  setTermsOfUseList,
  savePolicyAgreeAndValidateRoom,
}) => {
  const [ isCheckedAll, setIsCheckedAll ] = useState(false);
  const [ isCheckedRequiredAll, setIsCheckedRequiredAll ] = useState(false);

  const navigate = useNavigate();

  const checkAll = () => {
    const newTermsOfUseList = Array.from(termsOfUseList);
    newTermsOfUseList.map((termsOfUse, index) => {
      termsOfUse.agree_yn = !isCheckedAll;
      return termsOfUse;
    });
    setTermsOfUseList(newTermsOfUseList);
    validateRequiredAll(newTermsOfUseList);
    setIsCheckedAll(!isCheckedAll);
  };

  const checkOne = (index) => {
    const newTermsOfUseList = Array.from(termsOfUseList);
    newTermsOfUseList[index].agree_yn = !newTermsOfUseList[index].agree_yn;
    setTermsOfUseList(newTermsOfUseList);
    validateCheckedAll(newTermsOfUseList);
    validateRequiredAll(newTermsOfUseList);
  };

  const validateCheckedAll = (changedTermsOfUseList) => {
    const isCheckedAll = _.findIndex(changedTermsOfUseList, { agree_yn: false });
    if (isCheckedAll < 0) setIsCheckedAll(true);
    else setIsCheckedAll(false);
  };

  const validateRequiredAll = (changedTermsOfUseList) => {
    const isCheckedRequiredAll = _.findIndex(changedTermsOfUseList, { required: true, agree_yn: false });
    if (isCheckedRequiredAll < 0) setIsCheckedRequiredAll(true);
    else setIsCheckedRequiredAll(false);
  };

  const moveToTermsOfUseDetails = (termsOfUseItem) => {
    navigate('/terms_of_use/details', {
      state: {
        title: termsOfUseItem.policyTitle,
        description: termsOfUseItem.policyText,
      },
    });
  };

  const toMaskByPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;
    const onlyNumber = phoneNumber.replaceAll('-', '');
    return toMask(onlyNumber, onlyNumber.length - 8, onlyNumber.length - 3);
  };

  return (
    <>
      <div className='room-select-before-container'>
        <p className='resort-name'>
          {bookingItem.hotelName || '-'}
        </p>
        <span className='roomtype-name'>
          {bookingItem.roomTypeName || '-'}
        </span>
        <span className='period'>
          {`${parseEnDateByDayOfWeek(bookingItem.arrivalDate) || ''} - ${parseEnDateByDayOfWeek(bookingItem.departureDate) || ''} | ${bookingItem.nights || '-'} Night`}
        </span>
        <span className='amount'>
          {`결제 예정 금액: ${parseToAmount(bookingItem.totalAmount)}원`}
        </span>
        <p className='booking-details-title mt-40'>
          투숙객 정보
        </p>
        <div className='booking-details-info'>
          <span className='booking-details-info-label'>
            투숙객 이름
          </span>
          <span className='booking-details-info-value'>
            {bookingItem.accompanyName || '-'}
          </span>
        </div>
        <div className='booking-details-info'>
          <span className='booking-details-info-label'>
            휴대폰 번호
          </span>
          <span className='booking-details-info-value'>
            {toMaskByPhoneNumber(bookingItem.accompanyPhone) || '-'}
          </span>
        </div>
        <div className='horizontal-line mt-20 mb-25'></div>
      </div>
      <div className='horizontal-box mt-28 mb-28'></div>
      <div className='mr-30 ml-30 pb-78'>
        <p className='terms-of-use-list-title'>
          약관 동의
        </p>
        <span className='terms-of-use-list-description'>
          아래 약관 내용에 동의 후 서비스 이용이 가능합니다.
        </span>
        <div className='agree-checkbox-container'>
          <Checkbox isChecked={isCheckedAll} onCheck={checkAll} />
          <span className='agree-checkbox-text w-unset mr-4 fw-500'>
            전체 동의
          </span>
        </div>
        <div className='horizontal-line mt-17 mb-20'></div>
        {termsOfUseList.map((termsOfUseItem, index) => termsOfUseItem.enabled ? (
          <div className='agree-checkbox-container mb-18' key={index}>
            <Checkbox isChecked={termsOfUseItem.agree_yn} onCheck={() => checkOne(index)} />
            <span className='agree-checkbox-text'>
              {`${termsOfUseItem.policyTitle} ${termsOfUseItem.required ? '(필수)' : ''}`}
            </span>
            <img className='arrow-right p-absolute right-0' src='../../assets/images/arrow-right.png' alt='' onClick={() => moveToTermsOfUseDetails(termsOfUseItem)} />
          </div>
        ) : '')}
        <button className='basic-button d-block mt-35' disabled={!isCheckedRequiredAll} onClick={savePolicyAgreeAndValidateRoom}>
          확인
        </button>
      </div>
    </>
  );
};

export default TermsOfUseList;