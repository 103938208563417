import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PaymentSuccess from '../components/views/PaymentSuccess';
import ErrorPopup from '../components/views/ErrorPopup';
import Spinner from '../components/commons/Spinner';
import * as alpensiaApi from '../api/alpensia';

const PaymentResultContainer = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isPaymentSuccess, setIsPaymentSuccess ] = useState(false);
  const [ paymentSuccessData, setPaymentSuccessData ] = useState({});
  const [ isOpenErrorPopup, setIsOpenErrorPopup ] = useState(false);
  const [ ErrorMessageTitle, setErrorMessageTitle ] = useState('');
  const [ ErrorMessageDescription, setErrorMessageDescription ] = useState('');

  const navigate = useNavigate();

  const [ searchParams, setSearchParams ] = useSearchParams();
  const hotelCode = encodeURIComponent(searchParams.get('h'));
  const confirmationNo = encodeURIComponent(searchParams.get('r'));
  const token = searchParams.get('token');
  const return_path = searchParams.get('return_path');
  const imp_uid = searchParams.get('imp_uid');
  const merchant_uid = searchParams.get('merchant_uid');
  const imp_success = searchParams.get('imp_success');
  const error_code = searchParams.get('error_code');
  const error_msg = searchParams.get('error_msg');

  const openPaymentSuccess = () => {
    setIsPaymentSuccess(true);
  };

  const closePaymentSuccess = () => {
    setIsPaymentSuccess(false);
  };

  const openErrorPopup = () => {
    setIsOpenErrorPopup(true);
  };

  const closeErrorPopup = () => {
    setIsOpenErrorPopup(false);
  };

  const getReservation = async () => {
    try {
      const { data: responseGetReservation } = await alpensiaApi.getReservation({ hotelCode, confirmationNo });
      if (responseGetReservation.resultCode === '1000') return responseGetReservation.data.reservInfo;
      else throw new Error(`${responseGetReservation.resultCode}, ${responseGetReservation.msg}`);
    } catch (error) {
      setErrorMessageTitle('예약조회 실패');
      throw error;
    }
  }

  const getPaymentResult = async () => {
    try {
      const { data: responsePaymentResult } = await alpensiaApi.getPaymentResult({
        accessToken: `Bearer ${token}`,
        imp_uid,
        merchant_uid,
      });
      if (responsePaymentResult.resultCode === '1000') return responsePaymentResult.data.importPayment;
      else throw new Error(`${responsePaymentResult.resultCode}, ${responsePaymentResult.msg}`);
    } catch (error) {
      setErrorMessageTitle('결제 실패');
      throw error;
    }
  };

  const requestCheckin = async () => {
    try {
      const { data: responseCheckin } = await alpensiaApi.requestCheckin({
        accessToken: `Bearer ${token}`,
        hotelCode,
        confirmationNo,
        specialCodeYn: true,
        payRoutingYn: true,
      });
      if (responseCheckin.resultCode === '1000') openPaymentSuccess();
      else throw new Error(`${responseCheckin.resultCode}, ${responseCheckin.msg}`);
    } catch (error) {
      setErrorMessageTitle('체크인 실패');
      throw error;
    }
  };

  const validateAndRequestCheckin = async () => {
    try {
      setIsLoading(true);
      const reservInfo = await getReservation();
      if (reservInfo.comReservationStatus === 'DUEIN') {
        const paymentData = await getPaymentResult();
        if (paymentData.status === 'paid') {
          setPaymentSuccessData({
            cardName: paymentData.card_name,
            cardQuota: paymentData.card_quota,
            pgTid: paymentData.pg_tid,
            paidAt: paymentData.paid_at,
          });
          await requestCheckin();
        } else throw new Error(`${paymentData.status}, 결제가 완료되지 않았습니다`);
      } else navigate(`/?h=${hotelCode}&r=${confirmationNo}`);
    } catch (error) {
      if (return_path) navigate(`/${return_path}/?h=${hotelCode}&r=${confirmationNo}`, {
        state: {
          paymentYn: 'N',
        },
      });
      else {
        setErrorMessageDescription('정상적인 경로가 아닙니다.\n브라우저를 닫고 처음부터 다시 진행해주세요.');
        openErrorPopup();
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (imp_success) validateAndRequestCheckin();
    else {
      if (return_path) navigate(`/${return_path}/?h=${hotelCode}&r=${confirmationNo}`, {
        state: {
          paymentYn: 'N',
        },
      });
      else {
        setErrorMessageTitle('결제 실패');
        setErrorMessageDescription('정상적인 경로가 아닙니다.\n브라우저를 닫고 처음부터 다시 진행해주세요.');
        openErrorPopup();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PaymentSuccess
        isOpen={isPaymentSuccess}
        onClose={closePaymentSuccess}
        data={paymentSuccessData}
        hotelCode={hotelCode}
        confirmationNo={confirmationNo}
      />
      <ErrorPopup
        isOpen={isOpenErrorPopup}
        onClose={closeErrorPopup}
        title={ErrorMessageTitle}
        description={ErrorMessageDescription}
      />
      <Spinner
        isLoading={isLoading}
      />
    </>
  );
};

export default PaymentResultContainer;