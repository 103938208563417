import React from 'react';

const Modal = ({
  isOpen,
  isClosable,
  onClose,
  title,
  size,
  customStyle,
  Content,
}) => {
  return (
    <>
      <div className='modal' hidden={!isOpen}>
        <div className={`modal-body ${customStyle} ${size}`}>
          <div className='close-button-container' hidden={!isClosable} onClick={onClose}>
            <img className='close-button' src='../../assets/images/close-button.png' alt='' />
          </div>
          <div className='modal-title-container' hidden={!title}>
            <span className='modal-title'>{title}</span>
          </div>
          <div className={`modal-content-container ${title ? 'mt-52':''}`}>
            {Content}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;